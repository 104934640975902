document.addEventListener('turbolinks:load', function() {

  const thumbnailEl = '.profile-listing-video-thumbnail';
  let thumbnailElCollection = document.querySelectorAll(thumbnailEl);
  let hlsLoaded = false;

  function setupVideo(parentEl) {
    parentEl.addEventListener('click', function(event) {
      event.preventDefault();
      const listingVideoEl = '.listing-video-embed';
      const thumbnailContainer = event.target.closest(thumbnailEl);
      const videoID = `video-${thumbnailContainer.dataset.videoId}`;
      const videoURL = thumbnailContainer.dataset.videoUrl;
      const videoContainer = event.target.closest(listingVideoEl);
      videoContainer.innerHTML = `<video controls autoplay id="${videoID}" class="embed-responsive-item"></video>`;

      if (!hlsLoaded) {
        const hlsScript = document.createElement('script');
        hlsScript.src = 'https://unpkg.com/hls.js/dist/hls.min.js';
        hlsScript.onload = function() {
          hlsLoaded = true;
          var hls = new Hls();
          hls.loadSource(videoURL);
          hls.attachMedia(document.getElementById(videoID));
        };
        document.head.appendChild(hlsScript);
      } else {
        var hls = new Hls();
        hls.loadSource(videoURL);
        hls.attachMedia(document.getElementById(videoID));
      }

    });
  };

  // Setup video for each listing
  function setupAllVideos() {
    const thumbnailEl = '.profile-listing-video-thumbnail';
    let thumbnailElCollection = document.querySelectorAll(thumbnailEl);

    if (thumbnailElCollection.length > 0) {
      thumbnailElCollection.forEach(function(el) {
        setupVideo(el);
      });
    }
  }

  function setupVideoObserver() {
    // Setup video for each newly added listing to the DOM
    const parentEl = document.querySelector('.js-infinite-scroll');

    if (parentEl) {
      const initialNodes = Array.from(parentEl.querySelectorAll('.project-box'));

      const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          if (mutation.addedNodes) {
            console.log(mutation.addedNodes);
            mutation.addedNodes.forEach(function(node) {
              if (node.matches && node.matches('.project-box')) {
                // Check if the added node is a newly added node
                // by checking if it is present in the initial list of nodes
                if (initialNodes.indexOf(node) === -1) {
                  // Select the .profile-listing-video-thumbnail element inside the added node
                  const thumbnailElContainer = node.querySelector(thumbnailEl);

                  if (thumbnailElContainer) {
                    setupVideo(thumbnailElContainer);
                  }
                }
              }
            });
          }
        });
      });

      observer.observe(parentEl, { childList: true });
    }
  }

  window.setupVideo = setupVideo;
  window.setupAllVideos = setupAllVideos;
  window.setupVideoObserver = setupVideoObserver;

  setupAllVideos();
  setupVideoObserver();
});
