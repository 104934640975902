
require("@rails/ujs").start()
require("turbolinks").start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

//const images = require.context('../images', true)
//const imagePath = (name) => images(name, true)

global.$ = require('jquery')
//require("tether")
//require("popper.js")

const bootstrap = window.bootstrap = require('bootstrap');
//require("bootstrap-validator")
require("bootstrap-select")

global.jstz = require("jstz");

require('../pagy.js')

require("listings")
require("contents")
require("dictionary")
require("podcasts")
require("account/fields/buttons")
require("account/fields/select")
require("sprinkles")
require("clean_admin")

import "../../assets/stylesheets/critical.scss"

import "@fontsource/rubik/300.css"
import "@fontsource/rubik/400.css"
import "@fontsource/rubik/500.css"
