import Rails from '@rails/ujs';

document.addEventListener('turbolinks:load', function() {

  var tutorSearchFilter = document.getElementById('tutor-filter');
  var nonLanguageParamsChanged = false;

  if (tutorSearchFilter) {

    var tzInputEl = $('#tz');
    var timeZone = tzInputEl.val();
    if (timeZone === '' || timeZone === null || timeZone === undefined) {
      tzInputEl.val(jstz.determine().name());
    }

    var resetTutorSearchForm = function() {
      $('.js-filter-country').val(undefined);
      $('.js-filter-motivation').val(undefined);
      $('.js-filter-selectpicker').val(undefined);
      $('.js-price').val(undefined);
      $('.js-availability-time').val([]);
      $(tutorSearchFilter).submit();
    }

    var submitTutorSearchForm = function(refreshPage) {
      var $form = $(tutorSearchFilter);
      var formAction = $form.attr('action');
      var selectedLanguage = $('.js-language-select select').val();
      var languagesUrl;

      if (formAction.includes('classes/')) {
        let languageRegex = /^\/([a-z]+)-classes\//;
        languagesUrl = formAction.replace(languageRegex, '/' + selectedLanguage + '-classes/');
      } else if (formAction.includes('conversational')) {
        languagesUrl = '/conversational-' + selectedLanguage + "-classes-online";
      } else if (formAction.includes('zoom-skype')) {
        languagesUrl = '/' + selectedLanguage + "-lessons/zoom-skype";
      } else {
        languagesUrl = '/' + selectedLanguage + "-tutors-online";
      }

      if (nonLanguageParamsChanged) {
        $form.attr('action', languagesUrl);

        const formData = $form.serialize();

        if (refreshPage) {
          $form.submit();
        } else {
          Rails.fire(tutorSearchFilter, 'submit');
          var url = formAction + "?" + formData;

          // scroll to top of page if scroll position is past first page
          if (window.pageYOffset > window.innerHeight) {
            window.scrollTo(0, 0);
          }

          history.replaceState(history.state, "", formAction + "?" + formData);
        }
      } else {
        window.location = languagesUrl;
      }
    }

    var setupPriceFilter = function() {
      const $rangeSlider = $('.js-range-slider');
      let minPrice = $rangeSlider.data('minPrice');
      let maxPrice = $rangeSlider.data('maxPrice');
      let fromPrice = $rangeSlider.data('fromPrice');
      let toPrice = $rangeSlider.data('toPrice');
      let currency = $rangeSlider.data('currency');

      const rates = $rangeSlider.data('rates');

      $(".js-range-slider").ionRangeSlider({
        min: minPrice,
        max: maxPrice,
        hide_from_to: true,
        hide_min_max: true,
        type: 'double',
        skin: 'round',
        onChange: function (data) {
          var currencySymbol = data.input.data('prefix');
          if (currency != 'USD') {
            import(/* webpackChunkName: "cashify" */ 'cashify').then((cashify) => {
              let convertedFromPrice = cashify.convert(data.from, {to: currency, from: 'USD', base: 'USD', rates}).toFixed(2);
              let convertedToPrice = cashify.convert(data.to, {to: currency, from: 'USD', base: 'USD', rates}).toFixed(2);
              if (currencySymbol === '$') {
                var values = `${currencySymbol}${convertedFromPrice} ${currency}-${currencySymbol}${convertedToPrice} ${currency}`;
              } else {
                var values = `${currencySymbol}${convertedFromPrice} - ${currencySymbol}${convertedToPrice}`;
              }
              $('.js-selected-price').html(`<h5 class="text-muted">${values}</h5>`);
              $('#js-selected-price-dropdown').html(values)
            });
          } else {
            var values = `${currencySymbol}${data.from}-${currencySymbol}${data.to}`;
            $('.js-selected-price').html(`<h5 class="text-muted">${values}</h5>`);
            $('#js-selected-price-dropdown').html(values)
          }
        },
        onFinish: function (data) {
          nonLanguageParamsChanged = true;
          submitTutorSearchForm();
        }
      });
    }

    import(/* webpackChunkName: "ion-rangeslider" */ 'ion-rangeslider').then(() => {
      import(/* webpackChunkName: "ion-rangeslider-css" */ 'ion-rangeslider/css/ion.rangeSlider.css').then(() => {
        setupPriceFilter();
      });
    });

    // prevent dropdown from closing
    $('#js-price-dropdown-menu').on('click', function(e) {
      e.stopPropagation(false);
    });

    $('#js-availability-dropdown-menu').on('click', function(e) {
      e.stopPropagation(false);
    });

    $("input[name='time[]'], input[name='day[]']").each(function(index, checkbox) {
      $(checkbox).change(function(evt) {
        $(evt.target).parent().toggleClass('active', evt.target.checked);

        var allVals = [];
        // collect all the checked value labels
        $('#js-availability-dropdown-container :checked').each(function() {
          var label;

          if ($(this).closest('.day-label').length) {
            label = $(this).closest('.day-label').text().trim();
          } else {
            label = $(this).next('.availability-label').text();
          }
          allVals.push(label);
        });

        if (allVals.length == 1) {
          // display first value selected
          $('#js-selected-availability-dropdown').html(allVals[0]);
        } else if (allVals.length > 1) {
          // display first item plus count of items - 1
          var displayLabel = `${allVals[0]} + ${allVals.length - 1}`;
          $('#js-selected-availability-dropdown').html(displayLabel);
        } else {
          // display select times
          $('#js-selected-availability-dropdown').html('Select times');
        }
        nonLanguageParamsChanged = true;
        submitTutorSearchForm(false);
      });
    });

    $("input[name='day[]']").each(function(index, checkbox) {
      $(checkbox).change(function(evt) {
        $(evt.target).parent().toggleClass('active', evt.target.checked)
        nonLanguageParamsChanged = true;
        submitTutorSearchForm(false);
      });
    });

    $('.js-tutor-filter').find('select:not(.js-language-select)').change(function(evt) {
      nonLanguageParamsChanged = true;
      submitTutorSearchForm(false);
    });

    $('.js-language-select select').change(function(evt) {
      submitTutorSearchForm(true);
    });

    $('.enable-admin').change(function(evt) {
      nonLanguageParamsChanged = true;
      submitTutorSearchForm(true);
    });

    $('.show-hidden-profiles').change(function(evt) {
      nonLanguageParamsChanged = true;
      submitTutorSearchForm(true);
    });

    $('.js-rank-tutors').change(function(evt) {
      nonLanguageParamsChanged = true;
      submitTutorSearchForm(true);
    });

    $('#js-reset-form').on('click', function(e) {
      e.stopPropagation(false);
      resetTutorSearchForm();
    });
  }

});
